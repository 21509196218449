import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import HeroHandbook from '../../asset/pmax/hero-handbook.png';
import PmaxLogo from '../../asset/icons/pmaxlogo.jpg';
import CheckMark from '../../asset/pmax/icon-check.png';
import HandBookImage from '../../asset/pmax/img-payslip.png';
import IconMission from '../../asset/pmax/icon-mission.png';
import IconTech from '../../asset/pmax/icon-teach.png';
import IconWorkplace from '../../asset/pmax/icon-workplace.png';
import IconProductivity from '../../asset/pmax/icon-productivity.png';
import { Helmet } from 'react-helmet';
export default function EmployeeHandbook() {
    const onclickNav = () => {
        window.open(
            window.location.origin + "/handbookoptin.html",
            '_blank' // <- This is what makes it open in a new window.
          );
    }
    return (
        <>
        <Helmet>
            <script type="text/javascript">
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '5216654018407133');
            fbq('track', 'PageView');`}
            </script>
            <noscript>
            {`<img height="1" width="1"
            src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView
            &noscript=1" alt="facebook"/>`}
            </noscript>
        </Helmet>
        <Container fluid>
            <div className="top-bar">
                <img src={PmaxLogo} height="40px" alt="logo">
                </img>
            </div>
        </Container>
        <Container fluid className="pmax-employee-handbook-hero-1">
            <Row>
                <Col md={6} className="handbook-hero-img">
                    <img src={HeroHandbook} alt="hero">
                    </img>
                </Col>
                <Col md={6} className="hero-section-desc">
                    <h1>
                        7-Customisable Employee Handbook Templates
                    </h1>
                    <h4>
                        Build a stronger culture, onboard new hires faster and stay competitive in the talent market
                    </h4>
                    <Button onClick={onclickNav} className="hero-image-cta" varient="danger">DOWNLOAD NOW</Button>
                </Col>
            </Row>
        </Container>
        <Container fluid className="pmax-payslip-desc">
            <Container>
                <Row>
                    <Col md={12}>
                        <h6>Customisable Templates Included :</h6>
                    </Col>
                    <Col md={12}>
                        <Row>
                        <Col md={6} className="payslip-img">
                            <img src={HandBookImage} width="70%" alt="side"></img>
                        </Col>
                        <Col md={6}>
                            <ul>
                                <li><img src={CheckMark} width="20px" alt="benefits"/>Employment Basics</li>
                                <li><img src={CheckMark} width="20px" alt="benefits"/>Benefits Packages & Perks</li>
                                <li><img src={CheckMark} width="20px" alt="benefits"/>Workplace Policies</li>
                                <li><img src={CheckMark} width="20px" alt="benefits"/>Code of Conduct</li>
                                <li><img src={CheckMark} width="20px" alt="benefits"/>Working Hours, Vacation & Compensation</li>
                                <li><img src={CheckMark} width="20px"alt="benefits"/>Education Development</li>
                                <li><img src={CheckMark} width="20px" alt="benefits"/>Employee Resignation & Termination</li>
                            </ul>
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="pmax-slip-benefit handbook">
            <Row><Col md={12}>
            <h6>Your Handbook for Creating a High-Performing Workforce!</h6></Col></Row>
        </Container>
        <Container fluid className="pmax-bottom">
            <Container>
                <div className="bottom-desc">
                    Whether you are in a 200 person company or only have a handful of staff...
                    You aim to retain great talent, differentiate your organisation and ultimately, improve the business' bottom line.
                    <br/>
                    <br/>
                    Historically new hires are just left to figure out things on their own. But that's highly inefficient and frustrating for the employee.
                    <br/>
                    <br/>
                    And creating these onboarding processes are left to business owners and HR leaders to develop. So where to begin?
                    <br/>
                    <br/>
                    Our Employee Handbook is a collection of Customisable templates to help you
                    <br/>
                    <br/>
                    <br/>
                    <Row className="benefits-handbook">
                        <Col md={3}>
                            <div>
                                <img src={IconMission} width="60%" alt="benefits"/>
                                <div>
                                Align your staff in mission, values and culture
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <img src={IconTech} width="60%" alt="benefits"/>
                                <div>Teach your new hires how to position your product or service</div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <img src={IconProductivity} width="60%" alt="benefits"/>
                                <div>Onboard new hires faster and shorten their time to productivity.</div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <img src={IconWorkplace} width="60%" alt="benefits"/>
                                <div>Create a more cohesive workplace that’s beneficial to all</div>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                    <Button onClick={onclickNav} className="hero-image-cta">
                        DOWNLOADS THE TEMPLATE FREE
                    </Button>
                </div>
            </Container>
        </Container>
    </>
    )
}
