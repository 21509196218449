import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import PmaxLogo from '../../asset/icons/pmaxlogo.jpg';
import TeamMeet from '../../asset/pmax/team-meet.png';
import HandBookImage from '../../asset/pmax/img-payslip.png';
import ImageFacebook from '../../asset/icons/facebook.png';
import ImageInstagram from '../../asset/icons/instagram.png';
import { Helmet } from 'react-helmet';
export default function Ehandbook(props) {
    const navigateToSocialMedia = (media) => {
        if (media === "fb") {
            window.open(
                "https://facebook.com//SG.ASME",
                '_blank' // <- This is what makes it open in a new window.
              );
        } else {
            window.open(
                "https://instagram.com/asmesg",
                '_blank' // <- This is what makes it open in a new window.
              );
        }
    }
    const openPDF = (url) => {
        window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
        );
    }
    return (
        <>
        <Helmet>
            <script type="text/javascript">
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '5216654018407133');
            fbq('track', 'PageView');`}
            </script>
            <noscript>
            {`<img height="1" width="1"
            src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView
            &noscript=1" alt="facebook"/>`}
            </noscript>
        </Helmet>
        <Container fluid>
            <div className="top-bar">
                <img src={PmaxLogo} height="40px" alt="pmax">
                </img>
            </div>
        </Container>
        <Container fluid className="pmax-slip-benefit performance">
            <Row>
                <Col md={12} className="hero-section-desc e-payslip-hero-buld">
                    <h1 className="epayslip-center">
                        YOU'RE ALL SET!
                    </h1>
                    <h4 className="epayslip-center">
                        You can access your copy of "Employee Handbook Templates" here at any time
                    </h4>
                </Col>
            </Row>
        </Container>
        <Container fluid className="pmax-payslip-desc epay-slip-bg">
            <Container>
                <Row>
                    <Col md={12}>
                        <Row>
                        <Col md={6} className="payslip-img mobile-margin">
                        <img src={HandBookImage} width="70%" alt="side"></img>
                            <div className="epay-slip-right">
                                7 Customisable Employee Handbook Templates
                            </div>
                            <Button className="hero-image-cta" varient="danger" onClick={(e) => openPDF("../../pdf/employee-handbook.pdf")}>DOWNLOAD THE TEMPLATES</Button>
                        </Col>
                        <Col md={6} className="payslip-img epayslip-image-right">
                            <div className="epay-slip-right">
                                Did you know P-MAX also has workshops where SME's can get up to $10,000 Assistance Grant?
                            </div>
                            <img src={TeamMeet} width="90%" alt="payslip"></img>
                            <div className="epayslip-button">
                            <Button className="hero-image-cta" varient="danger" onClick={(e) => props.history.push('/pmax/homepage')}>APPLY NOW</Button>
                            </div>
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="pmax-home-footer">
            <Row>
                <Col md={6} className="footer-text-epayslip">
                    Copyright©P-MAX@ASME | Privacy Policy | Terms of Services
                </Col>
                <Col md={6} className="footer-privacy-policy epayslip-footer">
                    <img onClick={(e) => navigateToSocialMedia("fb")} src={ImageFacebook} alt="facebook" className="img-adjust" width="20px" />
                    <img onClick={(e) => navigateToSocialMedia()} src={ImageInstagram} alt="instagram" className="img-adjust" width="20px" />
                </Col>
            </Row>
        </Container>
    </>
    )
}
