import React from 'react'
import { Breadcrumb, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AboutUsBanner from '../asset/banner/aboutus-banner.png';
export default function Error() {
        return (
            <Container fluid className="Events-container">
                <Container fluid className="banner-container">
                    <img src={AboutUsBanner} alt="Event Banner" width="100%" className="banner-img"></img>
                    <h1>Contact</h1>
                </Container>
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item ><NavLink to="/">Home</NavLink></Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                </Container>
                <Container className="internal-container">
                        Something Went Wrong. Please go back to home <NavLink to="/">Home</NavLink>
                </Container>
            </Container>
        )
}
