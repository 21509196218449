import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import PmaxLogo from '../../asset/icons/pmaxlogo.jpg';
import PaySlipHerp from '../../asset/pmax/heropayslip.png';
import CheckMark from '../../asset/pmax/icon-check.png';
import PaySlip from '../../asset/pmax/payslip.png';
export default function ElectronicPaySlip() {
    const onclickNav = () => {
        window.open(
            window.location.origin + "/payslipcopy.html",
            '_blank' // <- This is what makes it open in a new window.
          );
    }
    return (<>
        <Helmet>
            <script type="text/javascript">
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '5216654018407133');
            fbq('track', 'PageView');`}
            </script>
            <noscript>
            {`<img height="1" width="1"
            src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView
            &noscript=1" alt="facebook"/>`}
            </noscript>
        </Helmet>
        <Container fluid>
            <div className="top-bar">
                <img src={PmaxLogo} height="40px" alt="logo">
                </img>
            </div>
        </Container>
        <Container fluid className="hero-section-pax">
            <Row>
                <Col md={6}>
                    <img src={PaySlipHerp} width="100%" alt="pay slip hero">
                    </img>
                </Col>
                <Col md={6} className="hero-section-desc">
                    <h1>
                        Electronic Payslips Template
                    </h1>
                    <h4>
                        Create Payslips for your employee in no time.
                    </h4>
                    <Button onClick={onclickNav} className="hero-image-cta" varient="danger">GIVE ME A COPY</Button>
                </Col>
            </Row>
        </Container>
        <Container fluid className="pmax-payslip-desc">
            <Container>
                <Row>
                    <Col md={12}>
                        <h6>Components of a Comprehensive payslip</h6>
                    </Col>
                    <Col md={12}>
                        <Row>
                        <Col md={6}>
                            <ul>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Organisation Logo</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Organisation Address</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Employee Name</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Employee Position</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Date of Employment</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Payment Period</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Payment Date</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Employee Account Details</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Salary Structure Breakdown</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>CPF & Deductions</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Gross Salary</li>
                            </ul>
                        </Col>
                        <Col md={6} className="payslip-img">
                            <img src={PaySlip} width="70%" alt="payslip"></img>
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="pmax-slip-benefit">
            <Row><Col md={12}>
            <h6>Improve Compliance, Employee Satisfaction and Productivity</h6></Col></Row>
        </Container>
        <Container fluid className="pmax-bottom">
            <Container>
                <div className="bottom-desc">
                    Employees are the most important resource in any organisation. It makes sense to ensure they are satisfied,
                    right?
                    <br/>
                    <br/>
                    With this template, you can create an easy to see, yet have a detailed breakdown of their earnings, taxes, deductions and allowances.
                    <br/>
                    <br/>
                    Sync this with your payroll system and all your records there are. It’s win-win for you and your employees.
                    <br/>
                    <Button onClick={onclickNav} className="bottom-cta hero-image-cta">
                        GIVE ME A COPY
                    </Button>
                </div>
            </Container>
        </Container>
    </>
    )
}
