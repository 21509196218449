import React from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import PmaxLogo from '../../asset/icons/pmaxlogo.jpg';
import HeroPerformance from '../../asset/pmax/hero-performance.png';
import CheckMark from '../../asset/pmax/icon-check.png';
import PerformanceImage from '../../asset/pmax/performaceImage.png';
export default function EmployeePerformance() {
    const onclickNav = () => {
        window.open(
            window.location.origin + "/accesstemplates.html",
            '_blank' // <- This is what makes it open in a new window.
          );
    }
    return (
        <>
        <Helmet>
            <script type="text/javascript">
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '5216654018407133');
            fbq('track', 'PageView');`}
            </script>
            <noscript>
            {`<img height="1" width="1"
            src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView
            &noscript=1" alt="facebook"/>`}
            </noscript>
        </Helmet>
        <Container fluid>
            <div className="top-bar">
                <img src={PmaxLogo} height="40px" alt="logo">
                </img>
            </div>
        </Container>
        <Container fluid className="hero-section-pax performance">
            <Row>
                <Col md={6} className="hero-section-desc">
                    <h1>
                        Employee Performance Review Templates
                    </h1>
                    <h4>
                        12 Templates to Help Motivate Employees and Drive Performance.
                    </h4>
                    <Button onClick={onclickNav} className="hero-image-cta performance-cta" varient="danger">ACCESS THESE TEMPLATES FREE</Button>
                </Col>
                <Col md={6}>
                    <img src={HeroPerformance} width="100%" alt="hero">
                    </img>
                </Col>
            </Row>
        </Container>
        <Container fluid className="pmax-payslip-desc">
            <Container>
                <Row>
                    <Col md={12}>
                        <h6>What you'll get:</h6>
                    </Col>
                    <Col md={12}>
                        <Row>
                        <Col md={6} className="payslip-img">
                            <img src={PerformanceImage} width="80%" alt="performance"></img>
                        </Col>
                        <Col md={6}>
                            <ul>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Quarterly check-in template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Mid-year performance review template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Annual performance review template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Self-assesment template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Peer review template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Team performance review template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>360 performance review template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Professional development template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Behaviour change template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Goal Setting template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>1-on-1 progress check template</li>
                                <li><img src={CheckMark} width="20px" alt="tick"/>Manager feedback template</li>
                            </ul>
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="pmax-slip-benefit performance">
            <Row><Col md={12}>
            <h6>Whats Gets Measured Gets Managed</h6></Col></Row>
        </Container>
        <Container fluid className="pmax-bottom">
            <Container>
                <div className="bottom-desc">
                    These days, annual performance review just don't cut it anymore.
                    <br/>
                    Ongoing performance conversations needs to happen in order to create a robust workforce and a comfortable office environment.
                    <br/>
                    <br/>
                    When well done, performance reviews  motivates employees, boost engagements, improve teamwork and bring the business to new heights.  
                    <br/>
                    <br/>
                    Boost your team's performance today.
                    <br/>
                    <br/>
                    <Button onClick={onclickNav} className="bottom-cta hero-image-cta">
                        ACCESS THESE TEMPLATES FREE
                    </Button>
                </div>
            </Container>
        </Container>
    </>
    )
}
