import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Container, Row, Card, Button } from 'react-bootstrap';
import '../stylesheets/events.css';
import NewsRoomBanner from '../asset/banner/newsroom-banner.png';
import { configuration } from '../config/config';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';
import { Helmet } from 'react-helmet';
const getPublicServiceAnnouncements = (number) => {
    return axios.get(`/public-service-announcements?_limit=${number}`)
        .then(res => {
            return res.data;
        });

}
export default function PublicServiceAnnouncement({ history }) {
    const [data, setData] = useState([]);
    const [numberOfTiles, setNumberOfTiles] = useState(9);
    const [noMoreToLoad, setNoMoreToLoad] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0)
        getPublicServiceAnnouncements(numberOfTiles)
            .then(res => {
                setData(res);
                if (res.length < 9) {
                    setNoMoreToLoad(false)
                }
            })
    }, []);
    const updateTiles = () => {
        setNumberOfTiles(numberOfTiles + 9)
        getPublicServiceAnnouncements(numberOfTiles + 9).then(res => {
            setData(res);
            if (res.length < numberOfTiles + 9) {
                setNoMoreToLoad(false)
            }
        })
    }
    const readMore = (item) => {
        history.push({ pathname: `/article/public-service-announcements/${item.id}/${encodeURI(item.title)}`, state: { item } });
    }
    if (data && data.length > 0) {
        return (
            <>
            <Helmet>
            <script type="text/javascript">
                    {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
                    </script>
                    <noscript>
                    {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
                    </noscript>
            </Helmet>
            <Container fluid className="Events-container psa-container">
                <Container fluid className="banner-container">
                    <img src={NewsRoomBanner} alt="Event Banner" width="100%" className="banner-img"></img>
                    <h1>Public Service Announcements</h1>
                </Container>
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">
                                <NavLink to="/">Home</NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink to="/publicserviceannouncement">Public Service Announcements</NavLink>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                </Container>
                <Container className="member-container">
                    <Row>
                        {data.length > 0 && data.map((value, index) => {
                            return <Col md={4} className="event-container">
                                <Card className="event-card">
                                    {value && value.tileImage[0] && value.tileImage[0].formats && value.tileImage[0].formats.large ? <Card.Img onClick={(e) => readMore(value)} variant="top" src={configuration.strapiURL + value.tileImage[0].formats.large.url} /> :
                                        value && value.tileImage[0] && value.tileImage[0].formats && value.tileImage[0].formats.medium ? <Card.Img onClick={(e) => readMore(value)} variant="top" src={configuration.strapiURL + value.tileImage[0].formats.medium.url} /> :
                                            value && value.tileImage[0] && value.tileImage[0].formats && value.tileImage[0].formats.small ? <Card.Img onClick={(e) => readMore(value)} variant="top" src={configuration.strapiURL + value.tileImage[0].formats.small.url} /> : null}
                                    <Card.Body>
                                        <Card.Text>
                                            <div className="event-title" onClick={(e) => readMore(value)}>{value.title}</div>
                                            <div className="psa-sub">{value.tileDescription}</div>
                                        </Card.Text>
                                        <div className="psa-readmore" onClick={(e) => readMore(value)}>Read More</div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        })}
                    </Row>
                    {noMoreToLoad && <Row className="load-more">
                        <Col md={12}>
                            <Button variant="primary" onClick={updateTiles}>Load More</Button>
                        </Col>
                    </Row>}
                </Container>
            </Container></>
        )
    } else {
        return <Loading></Loading>
    }
}